import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Button from "../components/button";
import Background from "../components/graphics/Background";
import "../components/styles.css";

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Container = styled.div`
  text-align: center;
  overflow: hidden;
  height: 100%;
`;
const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: -10;
  ${({ backdrop }) =>
    backdrop &&
    `
  opacity: 0.1
`}
`;

const IndexPage = () => {
  return (
    <Layout flex>
      <SEO title="Dress Up 4 Santa" />

      <BackgroundContainer backdrop>
        <Background />
      </BackgroundContainer>

      <Container>
        <ContentContainer>
          <div style={{ margin: 40, textAlign: "center" }}>
            <Button to="/start">Warning: Unexpected Element Ahead</Button>
          </div>
        </ContentContainer>
      </Container>
    </Layout>
  );
};

export default IndexPage;
